<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'Status',
      }
    },

    computed: {
      ...mapState({
        options: (state) => state.mycontrols.evidenceFileStatuses,
        selectedOptions(_, getters) {
          if (this.localFilters) {
            return this.options.filter((opt) =>
              this.localFilters.status?.includes(opt.value)
            )
          } else {
            return this.options.filter((opt) =>
              getters
                .getDataByPath(this.savedFiltersStatePath + '.status')
                .includes(opt.value)
            )
          }
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getMyControlsStatuses')
    },
  }
</script>
