import R3sFetch from './R3sFetch'
import { handleFetchResponse } from './ApiHelpers'

export default {
  async getStatuses() {
    const response = await R3sFetch(`/api/1.0/mycontrols/statuses/get`)
    return await handleFetchResponse(response)
  },

  async getAllTags() {
    const response = await R3sFetch(`/api/1.0/mycontrols/tags/get`)
    return await handleFetchResponse(response)
  },

  async getTeamFrameworks(getMetrics = false) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/team/frameworks/get/all${
        getMetrics ? '?getMetrics=true' : ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getPlatformIntelligenceFrameworks({ getArchived, getDeleted }) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/available/frameworks/get/all?archived=${getArchived}&deleted=${getDeleted}`
    )
    return await handleFetchResponse(response)
  },

  async getAvailableFrameworks() {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/available/frameworks/get/all?published=true`
    )
    return await handleFetchResponse(response)
  },

  async enableFramework(frameworkId, importR3SControls) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/available/framework/enable`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ frameworkId, importR3SControls }),
      }
    )
    return await handleFetchResponse(response)
  },

  async createUpdateTeamDomains(domains) {
    const response = await R3sFetch(`/api/1.0/mycontrols/team/domains/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(domains),
    })
    return handleFetchResponse(response)
  },

  async createUpdateTeamControlsDomains(domains) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/team/controls/groups/domains/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(domains),
      }
    )
    return handleFetchResponse(response)
  },

  async createUpdateTeamControlsScopes(groups) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/team/controls/groups/scopes/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(groups),
      }
    )
    return handleFetchResponse(response)
  },

  async getDomainDependencies(domainId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/team/domain/dependencies/get?domainId=${domainId}`
    )
    return await handleFetchResponse(response)
  },

  async getControlsDomainDependencies(domainId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/team/controls/groups/domain/dependencies/get?domainId=${domainId}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateTeamFrameworks(frameworks) {
    const response = await R3sFetch(`/api/1.0/mycontrols/team/frameworks/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(frameworks),
    })
    return handleFetchResponse(response)
  },

  async createFrameworkVersion(
    originVersionId,
    versionText,
    versionDescription
  ) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/frameworks/version/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          originVersionId,
          versionText,
          versionDescription,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async updateFrameworkVersion(version) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/frameworks/version/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          version,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async publishFrameworkVersion(frameworkVersionId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/frameworks/version/publish?frameworkVersionId=${frameworkVersionId}`
    )
    return await handleFetchResponse(response)
  },

  async getMappedFrameworkDetails(frameworkMapId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/mapped/framework/get?frameworkMapId=${frameworkMapId}`
    )
    return await handleFetchResponse(response)
  },

  async getFrameworkDetails(frameworkVersionId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/get?frameworkVersionId=${frameworkVersionId}`
    )
    return await handleFetchResponse(response)
  },

  async getOtherFrameworkVersions(frameworkVersionId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/versions/get?frameworkVersionId=${frameworkVersionId}`
    )
    return await handleFetchResponse(response)
  },

  async getFrameworkControlsByFrameworkVersionId(frameworkVersionId, filters) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/version/controls/get?frameworkVersionId=${frameworkVersionId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  /** passing null as frameworkMapId returns all of teams framework controls */
  async getFrameworkControls(frameworkMapId, filters) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/controls/get?frameworkMapId=${frameworkMapId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateFrameworkControls(controls) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/controls/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          controls,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async getFrameworkCurrentControlChangeLog(
    controlId,
    frameworkVersionId = null,
    frameworkMapId = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/control/changelog/get?controlId=${controlId}${
        frameworkVersionId ? `&frameworkVersionId=${frameworkVersionId}` : ''
      }${frameworkMapId ? `&frameworkMapId=${frameworkMapId}` : ''}`
    )
    return await handleFetchResponse(response)
  },

  async getFrameworkDomains({ frameworkVersionId, frameworkMapId }) {
    let queryString = ''
    if (frameworkVersionId) {
      queryString += `frameworkVersionId=${frameworkVersionId}`
    } else if (frameworkMapId) {
      queryString += `frameworkMapId=${frameworkMapId}`
    }
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/domains/get/all?${queryString}`
    )
    return await handleFetchResponse(response)
  },

  async getTeamControlsDomains(isDeleted = false) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/controls/groups/domains/get/all`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isDeleted }),
      }
    )

    return await handleFetchResponse(response)
  },

  async getTeamControlGroups(filters = {}, page = 1, perPage = 20) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/get/all?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&page=${page}&perPage=${perPage}`
    )
    return await handleFetchResponse(response)
  },

  async getTeamControlGroupScopes(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/scopes/get?controlGroupId=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async getFrameworkControlDetails(controlId, frameworkVersionId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/control/get?id=${controlId}&frameworkVersionId=${
        frameworkVersionId || ''
      }`
    )
    return await handleFetchResponse(response)
  },

  async getControlGroupDetails(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/group/get?id=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateTeamControls(controlGroups) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(controlGroups),
    })
    return handleFetchResponse(response)
  },

  async createSubControlGroupFromId(parentControlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/clone?parentControlGroupId=${parentControlGroupId}&makeSubControl=true`
    )
    return handleFetchResponse(response)
  },

  async getControlGroupOwners(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/owners/get?groupId=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async updateControlGroupOwners(controlGroups) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/owners/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(controlGroups),
    })
    return handleFetchResponse(response)
  },

  async getControlGroupCollaborators(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/collaborators/get?groupId=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async updateControlGroupCollaborators(controlGroups) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/collaborators/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(controlGroups),
      }
    )
    return handleFetchResponse(response)
  },

  async linkTasksToControlGroup(taskIds, groupId, reason) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/tasks/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        taskIds,
        groupId,
        reason,
      }),
    })
    return handleFetchResponse(response)
  },

  async linkGroupsToTask(groupIds, taskId, reason) {
    const response = await R3sFetch(`/api/1.0/mycontrols/task/groups/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        taskId,
        groupIds,
        reason,
      }),
    })
    return handleFetchResponse(response)
  },

  async linkTasksToEvidence(taskIds, evidenceId, reason) {
    const response = await R3sFetch(`/api/1.0/mycontrols/evidence/tasks/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        taskIds,
        evidenceId,
        reason,
      }),
    })
    return handleFetchResponse(response)
  },

  async linkEvidenceToTask(evidenceIds, taskId, reason) {
    const response = await R3sFetch(`/api/1.0/mycontrols/task/evidence/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        taskId,
        evidenceIds,
        reason,
      }),
    })
    return handleFetchResponse(response)
  },

  async unmapTaskFromControlGroup(taskId, controlGroupId) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/tasks/unmap`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        taskId: taskId,
        groupId: controlGroupId,
      }),
    })
    return handleFetchResponse(response)
  },

  async unmapTaskFromEvidence(taskId, evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/tasks/unmap`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          taskId,
          evidenceId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async linkEventsToControlGroup(eventIds, groupId, reason) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/events/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventIds,
        groupId,
        reason,
      }),
    })
    return handleFetchResponse(response)
  },

  async linkEventsToEvidence(eventIds, evidenceId, reason) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/events/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventIds,
          evidenceId,
          reason,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async unmapEventFromControlGroup(eventId, controlGroupId) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/events/unmap`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: eventId,
        groupId: controlGroupId,
      }),
    })
    return handleFetchResponse(response)
  },

  async unmapEventFromEvidence(eventId, evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/events/unmap`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId,
          evidenceId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async mapEventToControlGroup(eventId, controlGroupId, reason) {
    const response = await R3sFetch(`/api/1.0/mycontrols/events/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        eventId: eventId,
        groupId: controlGroupId,
        reason: reason,
      }),
    })
    return handleFetchResponse(response)
  },

  async getTeamEvidence(filters, page = 1, perPage = 20) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/get?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&page=${page}&perPage=${perPage}`
    )
    return await handleFetchResponse(response)
  },

  async getControlEvidence(controlId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/control/evidence/get?controlId=${controlId}`
    )
    return await handleFetchResponse(response)
  },

  async getEvidenceControlGroups(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/controls/get?evidenceId=${evidenceId}`
    )
    return await handleFetchResponse(response)
  },

  async getEvidenceDetails(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/details/get?evidenceId=${evidenceId}`
    )
    return handleFetchResponse(response)
  },

  async createUpdateTeamEvidence(evidence) {
    const response = await R3sFetch(`/api/1.0/mycontrols/evidence/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(evidence),
    })
    return handleFetchResponse(response)
  },

  async mapControlGroupsToEvidence(controlIds, evidenceId, reason = null) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/controls/map`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          controlIds,
          evidenceId,
          reason,
        }),
      }
    )

    return handleFetchResponse(response)
  },

  async mapControlsToEvidence(evidenceIds, controlId, reason = null) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/evidence/map`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        evidenceIds,
        controlId,
        reason,
      }),
    })

    return handleFetchResponse(response)
  },

  async unmapEvidenceControl(evidenceId, controlId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/controls/unmap`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          evidenceId,
          controlId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async linkFileToEvidence(evidenceId, fileId, reason) {
    const response = await R3sFetch(`/api/1.0/mycontrols/evidence/files/link`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        evidenceId: evidenceId,
        fileId: fileId,
        reason: reason,
        status: 'ready_for_review',
      }),
    })
    return handleFetchResponse(response)
  },

  async getEvidenceLinkedFiles(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/files/get?evidenceId=${evidenceId}`
    )
    return handleFetchResponse(response)
  },

  async getEvidenceTypicalEvidenceFiles(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/files/typical/get?evidenceId=${evidenceId}`
    )
    return handleFetchResponse(response)
  },

  async unlinkFileFromEvidence(fileMapId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/file/unlink`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileMapId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async unlinkFilesFromEvidence(fileMapIds) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/files/unlink`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileMapIds,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async getAllEvidenceFiles(filters) {
    const filtersString =
      filters && Object.keys(filters).length > 0
        ? `?filters=${encodeURIComponent(JSON.stringify(filters))}`
        : ''
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/files/get${filtersString}`
    )
    return handleFetchResponse(response)
  },

  async updateEvidenceFile(file) {
    const response = await R3sFetch(`/api/1.0/mycontrols/evidence/files/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(file),
    })
    return handleFetchResponse(response)
  },

  async linkTypicalEvidenceFile(evidenceId, fileId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/files/typical/link`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          evidenceId,
          fileId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async unlinkTypicalEvidenceFile(evidenceId, fileId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/files/typical/unlink`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          evidenceId,
          fileId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async updateEvidenceAssignee(evidence) {
    const response = await R3sFetch(`/api/1.0/mycontrols/evidence/owners/put`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(evidence),
    })
    return handleFetchResponse(response)
  },

  async updateEvidenceCollaborator(evidence) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/collaborators/put`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(evidence),
      }
    )
    return handleFetchResponse(response)
  },

  async toggleEvidenceAssignee(evidenceId, assigneeId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/owners/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          evidenceId: evidenceId,
          ownerId: assigneeId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async toggleEvidenceCollaborator(evidenceId, collaboratorId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/collaborators/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          evidenceId: evidenceId,
          collaboratorId: collaboratorId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async getEvidenceAssignees(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/owners/get?evidenceId=${evidenceId}`
    )
    return handleFetchResponse(response)
  },

  async getEvidenceCollaborators(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/collaborators/get?evidenceId=${evidenceId}`
    )
    return handleFetchResponse(response)
  },

  async getControlTasks(controlGroupId, filters, sorting) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/tasks/get?controlGroupId=${controlGroupId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&sorting=${encodeURIComponent(JSON.stringify(sorting))}`
    )
    return await handleFetchResponse(response)
  },

  async getControlEvents(controlGroupId, filters, sorting) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/events/get?controlGroupId=${controlGroupId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&sorting=${encodeURIComponent(JSON.stringify(sorting))}`
    )
    return await handleFetchResponse(response)
  },

  async getCurrentEvidenceTasks(evidenceId, filters, sorting) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/tasks/get?evidenceId=${evidenceId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&sorting=${encodeURIComponent(JSON.stringify(sorting))}`
    )
    return await handleFetchResponse(response)
  },

  async getCurrentEvidenceEvents(evidenceId, filters, sorting) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/events/get?evidenceId=${evidenceId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}&sorting=${encodeURIComponent(JSON.stringify(sorting))}`
    )
    return await handleFetchResponse(response)
  },

  async getControlGroupPoliciesMappings(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/policies/get?groupId=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async updateControlGroupPoliciesMappings(controlGroupId, policyIds) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/policies/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          groupId: controlGroupId,
          policyIds,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async mapControlGroupExternalPolicy(controlGroupId, policyName, policyUrl) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/policies/external/map`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          groupId: controlGroupId,
          policyName: policyName,
          policyUrl: policyUrl,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async unmapControlGroupExternalPolicy(mappingId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/policies/external/unmap`,
      {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          mappingId,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async getControlGroupTags(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/tags/get?groupId=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async updateControlGroupTags(controlGroupId, tags) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/tags/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ groupId: controlGroupId, tags }),
    })
    return handleFetchResponse(response)
  },

  async getCurrentControlControlMappings(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/mappedcontrols/get?groupId=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async updateCurrentControlControlMappings(
    controlGroupId,
    frameworkControlIds
  ) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/mappedcontrols/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ groupId: controlGroupId, frameworkControlIds }),
      }
    )
    return handleFetchResponse(response)
  },

  async updateFrameworkControlControlGroupMappings(
    frameworkControlId,
    controlGroupIds
  ) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/controls/groups/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ controlGroupIds, frameworkControlId }),
      }
    )
    return handleFetchResponse(response)
  },

  async getControlRiskMappings(groupId, filters) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/risks/get?groupId=${groupId}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async updateCurrentControlRiskMappings(groupId, riskIds) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/risks/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        riskIds,
        groupId,
      }),
    })
    return handleFetchResponse(response)
  },

  async mapItemToMyControl(groupId, riskId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/risks/put?groupId=${groupId}&riskId=${riskId}`
    )
    return await handleFetchResponse(response)
  },

  async unmapItemFromMyControl(groupId, riskId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/risks/delete?groupId=${groupId}&riskId=${riskId}`
    )
    return await handleFetchResponse(response)
  },

  async getControlGroupChangeLog(controlGroupId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/changelog/get?groupId=${controlGroupId}`
    )
    return await handleFetchResponse(response)
  },

  async updateControlComment(controlId, id, comment, mentions, deleteComment) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/comment/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        controlId,
        id,
        comment,
        mentions,
        deleteComment,
      }),
    })
    return handleFetchResponse(response)
  },

  async getControlComments(controlId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/groups/comments/get?controlId=${controlId}`
    )
    return await handleFetchResponse(response)
  },

  async getEvidenceTags(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/tags/get?evidenceId=${evidenceId}`
    )
    return await handleFetchResponse(response)
  },

  async getEvidenceComments(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/comments/get?evidenceId=${evidenceId}`
    )
    return await handleFetchResponse(response)
  },

  async updateEvidenceComment(
    evidenceId,
    id,
    comment,
    mentions,
    deleteComment
  ) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/comment/post`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          evidenceId,
          id,
          comment,
          mentions,
          deleteComment,
        }),
      }
    )
    return handleFetchResponse(response)
  },

  async updateEvidenceTags(evidenceId, tags) {
    const response = await R3sFetch(`/api/1.0/mycontrols/evidence/tags/post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ evidenceId: evidenceId, tags }),
    })
    return handleFetchResponse(response)
  },

  async getEvidenceChangeLog(evidenceId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/changelog/get?evidenceId=${evidenceId}`
    )
    return handleFetchResponse(response)
  },

  async getFrameworkControlControlGroups(frameworkControlId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/controls/groups/get?frameworkControlId=${frameworkControlId}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardDonutData(filters) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/dashboard/donut/get?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardStatusCounts(filters, type) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/dashboard/statuses/overview/get?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}${type ? `&type=${type}` : ''}`
    )
    return await handleFetchResponse(response)
  },

  async getDashboardStatusOverTime(filters) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/dashboard/status/overtime/get?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    )
    return await handleFetchResponse(response)
  },

  async getFilteredViews(type = null) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/filtered/views/get${type ? `?type=${type}` : ''}`
    )
    return await handleFetchResponse(response)
  },

  async getFilteredView(viewId) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/filtered/views/get?viewId=${viewId}`
    )
    return await handleFetchResponse(response)
  },

  async createUpdateFilteredView(view) {
    const response = await R3sFetch(`/api/1.0/mycontrols/filtered/view/put`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ view }),
    })
    return handleFetchResponse(response)
  },

  async generateExport(
    {
      type,
      assessment,
      structure,
      frameworkVersionsTeamsMapId,
      filters,
      preview = false,
    },
    signal = null
  ) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/generate/export`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type,
          assessment,
          structure,
          frameworkVersionsTeamsMapId,
          filters,
          preview,
        }),
      },
      { signal }
    )

    return await handleFetchResponse(response)
  },

  async getDetailsForControlExport(controlIds) {
    const response = await R3sFetch(`/api/1.0/mycontrols/groups/export`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        controlIds,
      }),
    })

    return await handleFetchResponse(response)
  },

  async getDetailsForEvidenceExport(evidenceIds) {
    const response = await R3sFetch(`/api/1.0/mycontrols/evidence/export`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        evidenceIds,
      }),
    })

    return await handleFetchResponse(response)
  },

  async getDetailsForFrameworkControlExport(controlIds) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/framework/controls/export`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          controlIds,
        }),
      }
    )

    return await handleFetchResponse(response)
  },

  async sendEvidenceReminders(evidenceIds) {
    const response = await R3sFetch(
      `/api/1.0/mycontrols/evidence/reminders/send`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          evidenceIds,
        }),
      }
    )
    return handleFetchResponse(response)
  },
}
