<script>
  import { mapState } from 'vuex'
  import BaseFilterComponent from '../../BaseFilterComponent.vue'

  export default {
    extends: BaseFilterComponent,

    data() {
      return {
        name: 'User',
      }
    },

    computed: {
      ...mapState({
        options(state) {
          return (state.team.teamAndInternalUsers || []).map((u) => ({
            text: u.name || u.username_email,
            value: u.id,
          }))
        },
        selectedOptions(_, getters) {
          if (this.localFilters) {
            return this.options.filter((opt) =>
              this.localFilters.uploaded_by?.includes(opt.value)
            )
          } else {
            return this.options.filter((opt) =>
              getters
                .getDataByPath(this.savedFiltersStatePath + '.uploaded_by')
                .includes(opt.value)
            )
          }
        },
      }),
    },

    mounted() {
      this.$store.dispatch('getTeamAndInternalUsers', false)
    },
  }
</script>
